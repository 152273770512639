const error_msg = {
  type_error: 'エラーが発生しました。',
  type_empty: '注文履歴がございません。',
  empty: '検索条件を確認し、再度お試しください',
  403: 'アクセスできません',
  504: 'サーバー側に接続できません。通信環境を確認の上、再度お試しください。',
  wrong_input: '半角英数字と半角記号8文字以上32文字以内で入力して下さい。',
  login: 'ログインに失敗しました',
  update_password: "パスワード変更に失敗しました",
  different_password: '新しいパスワードと再入力パスワードが一致しません。再度ご確認ください。',
}
const input_label = {
  login_id: 'ログインID:',
  password: 'パスワード:',
  old_password: '現在のパスワード:',
  new_password: ' 新しいパスワード:',
  confirm_password: '新しいパスワード（確認）:',
  submit: 'ログイン'
}
const success_msg = {
  update_password: "パスワード変更しました。再度ログインしてください。",
}
const header = {
  'order-history': '注文履歴・ラベル印刷',
}
export {
  input_label,
  error_msg,
  header,
  success_msg
}
