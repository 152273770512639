import { configureStore } from "@reduxjs/toolkit";
import { createReduxEnhancer } from "@sentry/react";

import authReducer from "store/modals/auth";
import commonReducer from "store/modals/common";

const sentryReduxEnhancer = createReduxEnhancer();

export default configureStore({
  reducer: {
    auth: authReducer,
    common: commonReducer,
  },
  enhancers: [sentryReduxEnhancer],
});
