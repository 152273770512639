import React, { useEffect, useState } from "react";
import { Form, Input, Button, Modal } from "antd";
import { useDispatch } from "react-redux";
import { captureException } from '@sentry/react';

import { regexString } from "core/utils/regex";
import { error_msg, input_label, success_msg } from "core/constant/ja";
import { authService } from "core/services/auth-service";
import { logout } from "store/modals/auth";
import { handleDisabled } from "core/utils/disabled";
import { apiService } from "core/services/api/api-service";
import showIcon from "assets/img/show.png";
import hideIcon from "assets/img/hide.png";
const layout = {
  labelCol: {
    span: 8,
    md: {
      span: 8,
    },
    xs: {
      span: 24,
    },
  },
  wrapperCol: {
    span: 16,
    md: {
      span: 16,
    },
    xs: {
      span: 24,
    },
  },
};
const tailLayout = {
  wrapperCol: {
    md: { offset: 8, span: 16 },
    xs: {
      offset: 0,
    },
  },
};
const UpdatePassword = () => {
  const [formValue, setFormValue] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [invalid, setInvalid] = useState({});
  useEffect(() => {
    return () => setFormValue({});
  }, []);
  const dispatch = useDispatch();
  const handleOk = () => {
    dispatch(logout());
  };
  const onFinish = async (values) => {
    setIsLoading(true);
    const data = {
      password: values.old_password.trim(),
      new_password: values.new_password.trim(),
    };
    await authService
      .updatePassword(data)
      .then((res) => {
        Modal.success({
          title: success_msg.update_password,
          onOk() {
            handleOk();
          },
        });
      })
      .catch((error) => {
        captureException(new Error(apiService.handleErrorMess(error) || error));
        Modal.error({
          title: error_msg.update_password,
          content: apiService.handleErrorMess(error),
          onOk() {
            if (error?.response?.status === 401) {
              handleOk();
            }
          },
        });
      });
    setIsLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      {...layout}
      name="basic"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      className="login-container"
    >
      <Form.Item
        label={input_label.old_password}
        name="old_password"
        rules={[
          {
            required: true,
            message: "",
          },
          () => ({
            validator(rule, value) {
              if (value && regexString(value)) {
                setFormValue({
                  ...formValue,
                  [rule.field]: value,
                });
                setInvalid({
                  ...invalid,
                  [rule.field]: false,
                });
                return Promise.resolve();
              } else {
                setFormValue({
                  ...formValue,
                  [rule.field]: "",
                });
                setInvalid({
                  ...invalid,
                  [rule.field]: true,
                });
                return Promise.reject(new Error(error_msg.wrong_input));
              }
            },
          }),
        ]}
      >
        <Input.Password
          iconRender={(visible) =>
            visible ? <img src={hideIcon} alt="icon" /> : <img src={showIcon} alt="icon" />
          }
        />
      </Form.Item>

      <Form.Item
        label={input_label.new_password}
        name="new_password"
        rules={[
          {
            required: true,
            message: "",
          },
          () => ({
            validator(rule, value) {
              if (value && regexString(value)) {
                setFormValue({
                  ...formValue,
                  [rule.field]: value,
                });
                setInvalid({
                  ...invalid,
                  [rule.field]: false,
                });
                return Promise.resolve();
              } else {
                setFormValue({
                  ...formValue,
                  [rule.field]: "",
                });
                setInvalid({
                  ...invalid,
                  [rule.field]: true,
                });
                return Promise.reject(new Error(error_msg.wrong_input));
              }
            },
          }),
        ]}
      >
        <Input.Password
          iconRender={(visible) =>
            visible ? <img src={hideIcon} alt="icon" /> : <img src={showIcon} alt="icon" />
          }
        />
      </Form.Item>
      <Form.Item
        label={input_label.confirm_password}
        name="confirm_password"
        dependencies={["new_password"]}
        rules={[
          {
            required: true,
            message: "",
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || !regexString(value)) {
                setFormValue({
                  ...formValue,
                  [rule.field]: "",
                });
                setInvalid({
                  ...invalid,
                  [rule.field]: true,
                  different: false,
                });
                return Promise.reject(new Error(error_msg.wrong_input));
              }
              if (getFieldValue("new_password")?.trim() !== value.trim()) {
                setInvalid({
                  ...invalid,
                  [rule.field]: false,
                  different: true,
                });
                return Promise.reject(new Error(error_msg.different_password));
              } else {
                setFormValue({
                  ...formValue,
                  [rule.field]: value,
                });
                setInvalid({
                  ...invalid,
                  [rule.field]: false,
                  different: false,
                });
                return Promise.resolve();
              }
            },
          }),
        ]}
      >
        <Input.Password
          iconRender={(visible) =>
            visible ? <img src={hideIcon} alt="icon" /> : <img src={showIcon} alt="icon" />
          }
        />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button
          type="primary"
          htmlType="submit"
          disabled={handleDisabled(formValue, invalid, isLoading, 3)}
        >
          パスワード変更する
        </Button>
      </Form.Item>
    </Form>
  );
};

export default UpdatePassword;
