import React from "react";
import { Router, Redirect, Route, Switch } from "react-router-dom";
import * as Sentry from '@sentry/react';

import { routing } from "Routing/Routing";
import { history } from "core/utils/history";
import { useSelector } from "react-redux";
import Header from "components/Header";

const SentryRoute = Sentry.withSentryRouting(Route);

const PathRouter = () => {
  const token = useSelector((state) => state.auth.token);

  return (
    <Router history={history}>
      <>
        <Header />
        <Switch>
          {routing.map((item, index) => (
            <SentryRoute key={index} path={item.path} exact={!!item.exact}>
              {!token && item.private ? (
                <Redirect to="/login" />
              ) : (
                <item.component />
              )}
            </SentryRoute>
          ))}
          <SentryRoute path="/">
            <Redirect to={token ? "/order-history" : "/login"} />
          </SentryRoute>
        </Switch>
      </>
    </Router>
  );
};

export default PathRouter;
