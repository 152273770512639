import axios from "axios";
import { url } from "./api-endpoint";
axios.defaults.timeout = 30000;
axios.defaults.baseURL = url;
axios.defaults.headers.common = {
  'x-api-key': process.env.REACT_APP_API_KEY
}

axios.interceptors.request.use((config) => {
  const token = JSON.parse(localStorage.getItem('token'));
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});
