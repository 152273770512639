import 'assets/styling/antd_custom/index.css';
import 'assets/styling/style.scss';
import PathRouter from 'Routing/PathRouter';
import { ConfigProvider } from 'antd';
import jaJP from 'antd/lib/locale/ja_JP.js';

function App() {
  return (
    <ConfigProvider locale={jaJP}>
      <main className="App">
        <PathRouter />
      </main>
    </ConfigProvider>
  );
}

export default App;
