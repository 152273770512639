import React from "react";
import { Link, useLocation } from "react-router-dom";
import { header } from "core/constant/ja";
import userIcon from "assets/img/user_icon.png";
import { Dropdown, Menu, Row, Col } from "antd";
import querystring from "query-string";
import { logout } from "store/modals/auth";
import { offAutoRefreshHistory } from "store/modals/common";
import { useSelector, useDispatch } from "react-redux";

const Header = () => {
  const dispatch = useDispatch();
  const info = useSelector((state) => state.auth.info);
  const { search } = useLocation();
  const parsedQuery = querystring.parse(search, { parseBooleans: true });
  const handleLogout = () => {
    dispatch(logout());
    dispatch(offAutoRefreshHistory());
  };
  const menu = (
    <Menu>
      <Menu.Item key="1">
        <p className="text-center">{info?.login_id}</p>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to="/update-password">パスワード変更</Link>
      </Menu.Item>
      <Menu.Item key="3">
        <p onClick={handleLogout}>ログアウト</p>
      </Menu.Item>
    </Menu>
  );

  const { pathname } = useLocation();
  return (
    <>
      {header[pathname.replaceAll("/", "")] && (
        <header className="container">
          <Row align="middle">
            <Col span={5}></Col>
            <Col span={14}>
              <h1 className="text-center text-bold my-4 fs-xxl">
                {header[pathname.replaceAll("/", "")]}
              </h1>
            </Col>
            {!parsedQuery.webview && (
              <Col span={5} className="icon-user">
                <Dropdown
                  overlay={menu}
                  trigger={["click"]}
                  placement="bottomCenter"
                >
                  <span
                    className="header-user d-flex align-items-center point-cursor"
                    rule="button"
                  >
                    {info?.shop_name}
                    <img src={userIcon} alt="icon" className="ml-2" />
                  </span>
                </Dropdown>
              </Col>
            )}
          </Row>
        </header>
      )}
    </>
  );
};

export default Header;
