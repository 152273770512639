import React, { useState, useEffect, useCallback, memo } from "react";
import { Form, DatePicker, Button, Select, Table, Modal, Switch } from "antd";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { captureException } from "@sentry/react";

import { apiService, ENDPOINT } from "core/services/api/api-service";
import ErrorMsg from "components/ErrorMsg";
import { logout } from "store/modals/auth";
import { toggleAutoRefreshHistory } from "store/modals/common";
const { Option } = Select;

const OrderList = (props) => {
  const { data, handleSearchChange, isLoading, error, setIsLoading } = props;
  const [form] = Form.useForm();
  const [selectStatus, setSelectStatus] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataRow, setDataRow] = useState([]);
  const autoRefreshHistory = useSelector(
    (state) => state.common.autoRefreshHistory
  );
  const dispatch = useDispatch();
  // Config
  // insert key for select
  useEffect(() => {
    const formatData = data.map((item) => ({ ...item, key: item.id }));
    setDataRow(formatData);
  }, [data]);
  // format status
  const optionStatus = [
    {
      value: "",
      optionName: "ALL",
      color: "",
    },
    {
      value: "new",
      optionName: "注文受付",
      color: "#1890FF",
    },
    {
      value: "error",
      optionName: "エラー",
      color: "#FF4D4F",
    },
    {
      value: "printing",
      optionName: "印刷待ち",
      color: "#FAAD14",
    },
    {
      value: "finish",
      optionName: "印刷済み",
      color: "#52C41A",
    },
  ];

  const columns = [
    {
      title: "受付番号",
      dataIndex: "receipt_no",
      key: "id",
      className: "no-col",
      render: (item) => <p className="text-center">{item}</p>,
    },
    {
      title: "注文日時",
      dataIndex: "created_at",
      key: "datetime",
      className: "date-col",
      render: (item) => (
        <>
          <p className="pc-only">{formatDate(item)}</p>
          <p className="tablet-only">{moment(item).format("HH:mm")}</p>
        </>
      ),
    },
    {
      title: () => (
        <>
          ラベルテキスト<span className="pc-only">1</span>
        </>
      ),
      dataIndex: "label_text_1",
      ellipsis: true,
      key: "text1",
      className: "text-col",
      render: (item, record) => (
        <>
          <p className="text-ellipsis" title={item}>
            {item}
          </p>
          <p className="text-ellipsis tablet-only" title={record.label_text_2}>
            {record.label_text_2}
          </p>
        </>
      ),
    },
    {
      title: "ラベルテキスト2",
      dataIndex: "label_text_2",
      ellipsis: true,
      key: "text2",
      className: "pc-col-only text-col",
    },
    {
      title: "ドリンク",
      dataIndex: "drink_name",
      key: "drink",
      className: "drink-col white-space-pre-line",
    },
    {
      title: "ステータス",
      dataIndex: "shop_order_status",
      key: "status",
      className: "drink-col",
      render: (item) => <>{getColor(item)}</>,
    },
  ];

  //Render Function
  const formatDate = (value) => {
    let result = value.replaceAll("-", "/");
    if (result.length === 19) {
      // format yyyy-mm-dd hh:mm:ss to yyyy/mm/dd hh:mm (remove seconds value)
      result = result.slice(0, -3);
    }
    return result;
  };
  const getColor = (item) => {
    if (item) {
      const result = optionStatus.find((el) => {
        return el.value === item;
      });
      return (
        <div className="order-list-status">
          <span
            className="order-list-dot"
            style={{ background: result?.color }}
          ></span>
          <p>{result?.optionName}</p>
        </div>
      );
    } else {
      return null;
    }
  };
  // Handle Function
  const rowSelection = {
    columnTitle: "選択",
    columnWidth: "5%",
    selectedRowKeys: selectedRowKeys,
    preserveSelectedRowKeys: false,
    getCheckboxProps: (_) => ({ disabled: autoRefreshHistory }),
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };
  const disabledDate = (current) => {
    const start = new Date("1990-01-01");
    return current && current < start;
  };
  const handleTableChange = (pagination, filters, sorter) => {};

  const handleSelectChange = (e) => {
    setSelectStatus(e);
  };

  const onFinish = useCallback(
    (fieldsValue, switchCurrentDate = false) => {
      // Should format date value before submit.
      let value = {};
      if (selectStatus) {
        value = { ...value, status: selectStatus };
      }
      if (switchCurrentDate) {
        form.setFieldsValue({
          datePicker: moment(),
        });
        value = {
          ...value,
          date: moment().format("YYYY-MM-DD"),
        };
      } else if (fieldsValue["datePicker"]) {
        value = {
          ...value,
          date: fieldsValue["datePicker"].format("YYYY-MM-DD"),
        };
      }
      setSelectedRowKeys([]);
      handleSearchChange(value, !switchCurrentDate);
    },
    [handleSearchChange, selectStatus, form]
  );

  useEffect(() => {
    if (autoRefreshHistory) {
      onFinish(null, true);
    }
  }, [autoRefreshHistory, onFinish]);

  const handlePrint = async () => {
    setIsLoading(true);
    const uri = `${ENDPOINT.shopOrder}/re-print`;
    await apiService
      .post(uri, { shop_order_ids: selectedRowKeys })
      .then((res) => {
        if (res.data.errors.length > 0) {
          Modal.error({
            title: "ラベル印刷にエラーが発生しました",
            content: `${res.data.errors.length}件が印刷を開始できませんでした。`,
            width: "600px",
          });
          const errorRows = dataRow;
          for (let i = 0; i < res.data.errors.length; i++) {
            const index = errorRows.findIndex((item) => {
              return item.id === res.data.errors[i];
            });
            if (index !== -1) {
              errorRows[index].shop_order_status = "error";
              errorRows[index].error = true;
            }
          }
          setDataRow([...errorRows]);
        } else {
          Modal.success({
            title: "ラベル印刷が開始しました",
            content: "印刷完了まで時間がかかりますので、少々お待ちください。",
            width: "600px",
          });
        }
        const successRow = dataRow;
        for (let i = 0; i < res.data.successes.length; i++) {
          const index = successRow.findIndex((item) => {
            return item.id === res.data.successes[i];
          });
          if (index !== -1) {
            successRow[index].shop_order_status = "printing";
            successRow[index].error = false;
          }
          setDataRow([...successRow]);
        }
      })
      .catch((e) => {
        captureException(new Error(apiService.handleErrorMess(e) || e));
        Modal.error({
          title: "ラベル印刷にエラーが発生しました",
          width: "600px",
          onOk() {
            if (e?.response?.status === 401) {
              dispatch(logout());
            }
          },
        });
      });
    setSelectedRowKeys([]);
    setIsLoading(false);
  };

  return (
    <>
      <div className="order-form py-4 mb-6">
        <div className="container container-small">
          <div className="order-list-header">
            <div className="header-left">
              <h3 className="title">注文履歴の自動更新</h3>
              <p className="description">
                現在の注文履歴一定間隔で自動更新します。
              </p>
            </div>
            <Switch
              checked={autoRefreshHistory}
              onClick={() => dispatch(toggleAutoRefreshHistory())}
            />
          </div>
          <Form
            form={form}
            name="time_related_controls"
            onFinish={onFinish}
            initialValues={{
              datePicker: moment(),
              selectDate: "",
            }}
            layout="inline"
            className={`mt-6 ${autoRefreshHistory ? "form-hidden" : ""}`}
          >
            <Form.Item name="datePicker" label="注文日：">
              <DatePicker
                format="YYYY/MM/DD"
                size="large"
                inputReadOnly
                disabledDate={disabledDate}
                disabled={isLoading || autoRefreshHistory}
              />
            </Form.Item>
            <div className="filter-group">
              <Form.Item
                label="ステータス："
                name="selectDate"
                className="border-lg-right"
              >
                <Select
                  onChange={handleSelectChange}
                  size="large"
                  disabled={isLoading || autoRefreshHistory}
                >
                  {optionStatus.map((item, index) => (
                    <Option value={item.value} key={index}>
                      {item.optionName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item className="flex-1 mr-0">
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  className="text-bold fs-md w-100"
                  disabled={isLoading || autoRefreshHistory}
                >
                  {"検索 "}
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
      <div className="container">
        <div className="d-flex justify-content-between align-items-end mb-3">
          <p className="mb-0 search-result">検索結果{dataRow.length || 0}件</p>
          <Button
            type="primary"
            disabled={
              selectedRowKeys.length <= 0 || isLoading || autoRefreshHistory
            }
            onClick={handlePrint}
            size="large"
            className="text-bold fs-lg m-0 w-min-150"
          >
            ラベル印刷 &nbsp;({selectedRowKeys.length})
          </Button>
        </div>
        {error.type ? (
          <ErrorMsg error={error} />
        ) : (
          <Table
            onRow={(record) => ({
              onClick: () => {
                if (!autoRefreshHistory) {
                  const result = selectedRowKeys;
                  const index = result.findIndex((item) => item === record.key);
                  if (index < 0) {
                    result.push(record.key);
                  } else {
                    result.splice(index, 1);
                  }
                  setSelectedRowKeys([...result]);
                }
              },
            })}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={dataRow}
            onChange={handleTableChange}
            bordered
            pagination={false}
            loading={isLoading}
            rowClassName={(record) => (record.error ? "error" : "")}
            className={`order-table ${
              isLoading && !dataRow.length ? "no-data" : ""
            }`}
          />
        )}
      </div>
    </>
  );
};

export default memo(OrderList);
