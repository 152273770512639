import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: JSON.parse(localStorage.getItem('token')) || null,
    info: JSON.parse(localStorage.getItem('info')) || null
  },
  reducers: {
    login: (state, action) => {
      localStorage.setItem('token', JSON.stringify(action.payload.token));
      const dataInfo = { login_id: action.payload.login_id, shop_name: action.payload.shop_name }
      localStorage.setItem('info', JSON.stringify(dataInfo));
      state.token = action.payload.token;
      state.info = dataInfo;
    },
    logout: (state) => {
      localStorage.removeItem('token');
      localStorage.removeItem('info');
      state.token = null;
      state.info = null;
    }
  },
})

// Action creators are generated for each case reducer function
export const { login, logout } = authSlice.actions

export default authSlice.reducer
