export const handleDisabled = (
  formValue,
  invalid,
  isLoading = false,
  fieldLength
) => {
  return (
    Object.values(invalid).find((x) => x) ||
    Object.values(formValue).findIndex((x) => x?.trim().length < 8) > -1 ||
    Object.values(formValue)?.length < fieldLength ||
    isLoading
  );
};
