import React, { useEffect, useState } from "react";
import { Form, Input, Button, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { captureException } from '@sentry/react';

import { regexString } from "core/utils/regex";
import { error_msg, input_label } from "core/constant/ja";
import { authService } from "core/services/auth-service";
import { login } from "store/modals/auth";
import { history } from "core/utils/history";
import { handleDisabled } from "core/utils/disabled";
import { apiService } from "core/services/api/api-service";
import showIcon from "assets/img/show.png";
import hideIcon from "assets/img/hide.png";
const layout = {
  labelCol: {
    span: 8,
    md: {
      span: 8,
    },
    xs: {
      span: 24,
    },
  },
  wrapperCol: {
    span: 16,
    md: {
      span: 16,
    },
    xs: {
      span: 24,
    },
  },
};
const tailLayout = {
  wrapperCol: {
    md: { offset: 8, span: 16 },
    xs: {
      offset: 0,
    },
  },
};
const Login = () => {
  const [formValue, setFormValue] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [invalid, setInvalid] = useState({});
  const token = useSelector((state) => state.auth.token);
  useEffect(() => {
    if (token) {
      history.push("/order-history");
    }
    return () => {
      setFormValue({});
    };
  }, [token]);
  const dispatch = useDispatch();

  const onFinish = async (values) => {
    const trimValue = Object.keys(values).reduce(
      (attrs, key) => ({
        ...attrs,
        [key]: values[key].trim(),
      }),
      {}
    );
    setIsLoading(true);
    await authService
      .login(trimValue)
      .then((res) => {
        const result = {
          token: res.data.token,
          shop_name: res.data.shop_name,
          login_id: values.login_id,
        };
        dispatch(login(result));
        history.push("/order-history");
      })
      .catch((error) => {
        captureException(new Error(apiService.handleErrorMess(error) || error));
        Modal.error({
          title: error_msg.login,
          content: apiService.handleErrorMess(error),
        });
      });
    setIsLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      {...layout}
      name="basic"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      className="login-container"
    >
      <Form.Item
        label={input_label.login_id}
        name="login_id"
        rules={[
          {
            required: true,
            message: "",
          },
          () => ({
            validator(rule, value) {
              if (value && regexString(value)) {
                setFormValue({
                  ...formValue,
                  [rule.field]: value,
                });
                setInvalid({
                  ...invalid,
                  [rule.field]: false,
                });
                return Promise.resolve();
              } else {
                setFormValue({
                  ...formValue,
                  [rule.field]: "",
                });
                setInvalid({
                  ...invalid,
                  [rule.field]: true,
                });
                return Promise.reject(new Error(error_msg.wrong_input));
              }
            },
          }),
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={input_label.password}
        name="password"
        rules={[
          {
            required: true,
            message: "",
          },
          () => ({
            validator(rule, value) {
              if (value && regexString(value)) {
                setFormValue({
                  ...formValue,
                  [rule.field]: value,
                });
                setInvalid({
                  ...invalid,
                  [rule.field]: false,
                });
                return Promise.resolve();
              } else {
                setFormValue({
                  ...formValue,
                  [rule.field]: "",
                });
                setInvalid({
                  ...invalid,
                  [rule.field]: true,
                });
                return Promise.reject(new Error(error_msg.wrong_input));
              }
            },
          }),
        ]}
      >
        <Input.Password
          iconRender={(visible) =>
            visible ? <img src={hideIcon} alt="icon" /> : <img src={showIcon} alt="icon" />
          }
        />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button
          type="primary"
          htmlType="submit"
          disabled={handleDisabled(formValue, invalid, isLoading, 2)}
        >
          {input_label.submit}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Login;
