import React from "react";
import { error_msg } from 'core/constant/ja';

const ErrorMsg = (props) => {
  const { error } = props;
  return (
    <div className={`error-container ${error.type !== 'empty' ? 'text-error' : ''}`} >
      <p>{error.type === 'empty' ? error_msg.type_empty : error_msg.type_error}</p>
      <p>{error.type === 'empty' ? error_msg.empty : error.message}</p>
    </div>
  );
};

export default ErrorMsg;
