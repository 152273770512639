import { createSlice } from '@reduxjs/toolkit'

export const commonSlice = createSlice({
  name: 'common',
  initialState: {
    autoRefreshHistory: !!JSON.parse(localStorage.getItem('autoRefreshHistory'))
  },
  reducers: {
    toggleAutoRefreshHistory: (state) => {
      localStorage.setItem('autoRefreshHistory', !state.autoRefreshHistory);
      state.autoRefreshHistory = !state.autoRefreshHistory;
    },
    offAutoRefreshHistory: (state) => {
      state.autoRefreshHistory = false;
      localStorage.setItem('autoRefreshHistory', false);
    }
  },
})

// Action creators are generated for each case reducer function
export const { toggleAutoRefreshHistory, offAutoRefreshHistory } = commonSlice.actions

export default commonSlice.reducer
