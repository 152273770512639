import React, { useState, useEffect, useRef, useCallback } from "react";
import queryString from "query-string";
import { useSelector, useDispatch } from "react-redux";
import { captureException } from "@sentry/react";
import { Modal } from "antd";

import { apiService, ENDPOINT } from "core/services/api/api-service";
import OrderList from "components/OrderList";
import { offAutoRefreshHistory } from "store/modals/common";
import { logout } from "store/modals/auth";
import { error_msg } from "core/constant/ja";

const OrderShop = () => {
  const currentDate = new Date();
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({
    date: currentDate.toISOString().split("T")[0],
    status: "",
  });
  const [isButtonSearchAction, setIsButtonSearchAction] = useState(true);
  const [isRequestingAPI, setIsRequestingAPI] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});
  const dispatch = useDispatch();
  const autoRefreshHistory = useSelector(
    (state) => state.common.autoRefreshHistory
  );
  const interval = useRef(null);
  const timeoutDuration = 5000;

  const getOrderAPI = useCallback(async () => {
    setIsLoading(true);
    setIsRequestingAPI(true);
    setError({});
    const paramString = queryString.stringify(filter);
    const uri = `${ENDPOINT.shopOrder}?${paramString}`;
    await apiService
      .get(uri)
      .then((res) => {
        setData(res.data.shop_orders);
        setError(
          res.data.shop_orders.length <= 0
            ? {
                type: "empty",
              }
            : {}
        );
      })
      .catch((error) => {
        captureException(new Error(apiService.handleErrorMess(error) || error));
        dispatch(offAutoRefreshHistory());
        if (error?.response?.status === 401) {
          Modal.error({
            title: error_msg.type_error,
            content: apiService.handleErrorMess(error),
            onOk() {
              dispatch(logout());
            },
          });
        } else {
          const errorMsg = autoRefreshHistory
            ? "データ更新に失敗したため、自動更新を無効にします。 お手数ですが、再度お試しください。"
            : apiService.handleErrorMess(error);
          Modal.error({
            title: errorMsg,
            width: "600px",
          });

          if (!autoRefreshHistory) {
            setData([]);
          }
        }
      });
    setIsButtonSearchAction(false);
    setIsRequestingAPI(false);
    setIsLoading(false);
  }, [filter, dispatch, autoRefreshHistory]);

  useEffect(() => {
    if (isButtonSearchAction) {
      getOrderAPI();
    }
  }, [getOrderAPI, isButtonSearchAction]);

  useEffect(() => {
    if (autoRefreshHistory) {
      interval.current = setInterval(() => {
        if (!isRequestingAPI) {
          getOrderAPI();
        }
      }, timeoutDuration);
    }

    return () => {
      clearInterval(interval.current);
    };
  }, [autoRefreshHistory, isRequestingAPI, getOrderAPI]);

  const handleSearchChange = useCallback((params, isButtonSearchAction) => {
    setFilter((prev) => ({
      ...prev,
      date: params.date,
      status: params.status,
    }));
    setIsButtonSearchAction(isButtonSearchAction);
  }, []);

  return (
    <OrderList
      data={data}
      handleSearchChange={handleSearchChange}
      isLoading={isLoading}
      error={error}
      setIsLoading={setIsLoading}
    />
  );
};

export default OrderShop;
