const url = process.env.REACT_APP_URL;
const endpoint = {
  products: 'products',
  shopOrder: 'shop-orders',
  login: 'login',
  updatePassword: 'update-password',
};

export {
  url,
  endpoint
}
