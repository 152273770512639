import { Login, OrderShop, UpdatePassword } from "pages/Index";
export const routing = [
  {
    path: "/login",
    component: () => <Login />,
    exact: true,
    private: false,
  },
  {
    path: "/update-password",
    component: () => <UpdatePassword />,
    exact: true,
    private: true,
  },
  {
    path: "/order-history",
    component: () => <OrderShop />,
    exact: true,
    private: true,
  },
];
