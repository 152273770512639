import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./store/store";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { history } from "core/utils/history";
import moment from "moment";

const sentryTracingOrigins = [];
if (!process.env.REACT_APP_STAGING && !process.env.REACT_APP_PRODUCTION) {
  sentryTracingOrigins.push("localhost", /^\//);
}
sentryTracingOrigins.unshift(process.env.REACT_APP_APP_URL);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: sentryTracingOrigins,
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  sampleRate: 1,
  tracesSampleRate: 0.05,
  attachStacktrace: true,
  debug: !process.env.REACT_APP_PRODUCTION,
  normalizeDepth: 10,
  initialScope: {
    tags: { system: "Shop Admin" },
  },
  release: `TAG-Shop-Admin_${moment().format("YYYY-MM-DD")}`,
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
